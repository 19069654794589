<template>
  <div>
    <Header
      :showMap="true"
      :showListButton="showList"
      ref="header"
    />
    <section id="list" class="forScreenReaders" v-if="this.pivotVicini.length > 0 || this.nearestPivot !== null">
      <header><h3>{{$t('tuttiIPercorsiVicini')}}</h3></header>
      <b-container fluid>
        <CardList
              list-title="Tutti i pivot"
              :elems="this.allPivots" 
              :handler="this.selectPivot"
              :thumbnail="this.thumbnailSrc"
              :alt="this.alt"
              :title="this.cardTitle"
              :body="this.cardBody"
              v-if="!this.loading"
              />
        <b-container fluid id="listbutton" class="centered">
          <n-button @click="showList"><i class="now-ui-icons location_compass-05"></i></n-button>
        </b-container>
      </b-container>
    </section>
    <alert id="information" type="danger" v-if="positionError !== null">{{positionError}}</alert>
    <section>
      <b-container v-if="!this.loading && this.nearestPivot === null">
        <b-container fluid>
              <b-card class="overflow-hidden" style="margin-top:30px; background-color:#ffe9ba">
                  <b-card-body>
                      <b-card-text class="centeredText">
                          {{$t('nessunEl')}}
                      </b-card-text>
                  </b-card-body>
              </b-card>
          </b-container>
      </b-container>
      <b-container>
        <router-view :key="$route.path"/>
      </b-container>
    </section>
  </div>
</template>
<script>
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter
import {Alert} from '@/components'
import CardList from './components/CardList.vue'
import Header from './components/Header.vue'
import {Button} from '@/components'

const Common = require('@/Common.vue').default
export default {
  name: 'starter',
  components: {Alert,CardList,Header,[Button.name]: Button},
  data: function(){
    return {
      pivotVicini : [], // Tutti i pivot vicini
      loading: true,
      positionError: null, // Messaggio di errore in caso di problemi con la geolocalizzazione,
      thumbnails: {}, // Pivot id => media object,
      nearestPivot: null, // Il pivot più vicino.
      returning : false,
      onHomePage: 0,
      orangeIcon: new L.Icon({
          iconUrl: 'https://exptriangolazioni.ontomap.eu/out2.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [27,34],
          iconAnchor: [13,34.5],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
      }),
      blackIcon: new L.Icon({
          iconUrl: 'https://exptriangolazioni.ontomap.eu/output-onlinepngtools_others.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [27,34],
          iconAnchor: [13,34.5],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
      })
    }
  },
  mounted : function(){
    // Get user's position
    this.getPosition()
  },
  methods: {
    showList: function(){
      var yourUl = document.getElementById("mapContainer");
      yourUl.style.display = yourUl.style.display === 'none' ? '' : 'none';
      document.getElementById("list").classList.toggle("forScreenReaders")
    },
    getPosition: function(){
      // TODO rimuovere fino al return incluso
      var position = {
        'coords' : {
          'latitude': 45.07096535,
          'longitude': 7.68580460488738
        }
      }
      this.updatePosition(position)
      return;
      /*if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.updatePosition,this.showError);
      } else { 
        //console.error("Geolocation is not supported by this browser.");
        this.positionError = this.$t('geolocNonSupportata');
      }*/
    },
    updatePosition: function(position){
      if(this.$store.getters.getPivotSelezionato !== null && this.$route.name !== 'index' && this.nearestPivot !== null){
        // Siamo in magliefrompivot
        this.pivotVicini.forEach(el => {
          el.marker.setIcon(this.blackIcon)
          el.marker.setZIndexOffset(0)
          el.marker._icon.classList.remove("huechange")
          el.marker._icon.classList.add("huestd")
        })
        this.nearestPivot.marker.setIcon(this.blackIcon)
        this.nearestPivot.setZIndexOffset(0)
        this.nearestPivot.marker._icon.classList.remove("huechange")
        this.nearestPivot.marker._icon.classList.add("huestd")
        this.$store.getters.getPivotSelezionato.marker.setIcon(this.orangeIcon)
        this.$store.getters.getPivotSelezionato.marker.setZIndexOffset(1000)
        this.$store.getters.getPivotSelezionato.marker._icon.classList.add("huechange")
        this.$store.getters.getPivotSelezionato.marker._icon.classList.remove("huestd")
        return
      }
      else if(this.$store.getters.getPivotSelezionato !== null && this.returning){
        // Siamo in index (ma stiamo tornando da magliefrompivot)
        this.pivotVicini.forEach(el => {
          if(el.marker){
            el.marker.setIcon(this.blackIcon)
            el.marker.setZIndexOffset(0)
            el.marker._icon.classList.remove("huechange")
            el.marker._icon.classList.add("huestd")
          }
        })
        if(this.nearestPivot.marker){
          this.nearestPivot.marker.setIcon(this.orangeIcon)
          this.nearestPivot.marker.setZIndexOffset(1000)
          this.nearestPivot.marker._icon.classList.add("huechange")
          this.nearestPivot.marker._icon.classList.remove("huestd")
        }
        this.selectPivot(this.nearestPivot)
        this.returning = false
        return
      }

      var self = this;
      var radius = this.$store.getters.getRadiusVal;
      this.$http.get(Common.baseUrl_Position+position.coords.latitude+','+position.coords.longitude+'&radius='+radius).then(
        response => {
          var elementi = [
              {
                "@context": "https://dev.triangolazioni.unito.it/api-context",
                "@id": "https://dev.triangolazioni.unito.it/api/mapping_markers/1",
                "@type": "o-module-mapping:Marker",
                "o:id": 1,
                "o:item": {
                "@id": "https://dev.triangolazioni.unito.it/api/items/15",
                "o:id": 15
                },
                "o:media": null,
                "o-module-mapping:lat": 45.06813521932428,
                "o-module-mapping:lng": 7.6839427980471555,
                "o-module-mapping:label": null
              },
              {
                "@context": "https://dev.triangolazioni.unito.it/api-context",
                "@id": "https://dev.triangolazioni.unito.it/api/mapping_markers/4",
                "@type": "o-module-mapping:Marker",
                "o:id": 4,
                "o:item": {
                "@id": "https://dev.triangolazioni.unito.it/api/items/40",
                "o:id": 40
                },
                "o:media": null,
                "o-module-mapping:lat": 45.07096535,
                "o-module-mapping:lng": 7.68580460488738,
                "o-module-mapping:label": "Palazzo Madama, Piazza Castello, Quadrilatero Romano, Circoscrizione 1, Turin, Torino, Piemont, Italy"
              }
          ]
          
          if(window.location.href.includes("maglie-pivot")){
            var found = false
            for(var i = 0; i < elementi.length; i++){
              if(elementi[i]["o:item"]["o:id"] == window.location.href.split("/").pop()){
                found = true
                break
              }
            }
          }
          else var found = true
          var self = this
          if(!found){
            Common.getElemById(self,window.location.href.split("/").pop(),pivotS => {
              if(pivotS.body.hasOwnProperty("o-module-mapping:marker")){
                Common.getElemByUrl(self,pivotS.body["o-module-mapping:marker"][0]["@id"],pivotC => {
                  elementi.push(pivotC.body)
                  self.initElems(elementi,position)
                })
              }
              else self.initElems(elementi,position)
            })
          }
          else{
            //TODO cambiare "elementi" in response.body
            this.initElems(elementi,position)
          }
        },
        response => {self.positionError = "Abbiamo riscontrato un problema... Riprova più tardi";}
      );
    },
    initElems: function(elementi,position){
      var nuoviRisultati = [];
      var self = this
      var waitingFor = elementi.length
      elementi.forEach(coord => {
        Common.getElemByUrl(self,coord["o:item"]["@id"], response => {
          var pivot = response.body
          pivot["lat"] = coord["o-module-mapping:lat"]
          pivot["lng"] = coord["o-module-mapping:lng"]
          nuoviRisultati.push(pivot);
          waitingFor--;
          if(waitingFor === 0){
            self.pivotVicini = nuoviRisultati
            var waiting = nuoviRisultati.length;
            nuoviRisultati.forEach(elem => {
              Common.getElementImages(this,elem,mediaList => {
                self.thumbnails[elem["o:id"]] = Common.findThumbnail(mediaList)
                waiting--
                if(waiting === 0){
                  self.loading = false
                }
              });
            })
            self.findNearest(position);
          }
        })
      })
    },
    distance: function(pivotCoord, position) {
      return Math.sqrt(Math.pow(pivotCoord.lat - position.coords.latitude, 2) + Math.pow(pivotCoord.lat  - position.coords.longitude, 2))
    },
    findNearest: function(position){
      var dist;
      var lowest_distance = Number.MAX_VALUE
      var current_low;
      this.nearestPivot = null
      for(var i = 0; i < this.pivotVicini.length; i++){
        dist = this.distance(this.pivotVicini[i],position)
        if(dist <= lowest_distance){
          //current_low = i
          current_low = 1 // TODO cambiare
          lowest_distance = dist
        }
      }
      this.nearestPivot = this.pivotVicini[current_low]
      this.pivotVicini.splice(current_low,1)
      var self = this
      if(this.$route.name === 'index'){
        this.map.initMapAndSetCurrent(this.nearestPivot["lat"],this.nearestPivot["lng"],Common.safeString(this.nearestPivot,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"]),this.nearestPivot)
        this.nearestPivot.marker.on('click',() => self.selectPivot(this.nearestPivot))
        //TODO
        /*this.pivotVicini.forEach(el => {
          this.map.addToMap(el["lat"],el["lng"],Common.safeString(el,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"]),el)
          el.marker.on('click',() => self.selectPivot(el))
        })*/
        this.selectPivot(this.nearestPivot)
      }
      else{
        Common.getElemById(this,window.location.href.split("/").pop(), function(r){
            if(r.body.hasOwnProperty("o-module-mapping:marker")){
              Common.getElemByUrl(self,Common.safeString(r.body,["o-module-mapping:marker",0,"@id"]), res => {
                var pivot = r.body
                pivot["lat"] = res.body["o-module-mapping:lat"]
                pivot["lng"] = res.body["o-module-mapping:lng"]
                Common.getElementMedia(self,r.body,mediaList => {
                    self.$store.commit('setPivotImages',mediaList)
                    self.$store.dispatch('setPivotSelezionato',[self,r.body])
                    self.map.initMapAndSetCurrent(pivot["lat"],pivot["lng"],Common.safeString(pivot,["tri:Titolo_"+self.$store.getters.getLanguage,0,"@value"]),pivot)
                    //self.map.addToMap(self.nearestPivot["lat"],self.nearestPivot["lng"],Common.safeString(self.nearestPivot,["tri:Titolo_"+self.$store.getters.getLanguage,0,"@value"]),self.nearestPivot)
                    //self.nearestPivot.marker.on('click',() => self.selectPivot(self.nearestPivot))
                    //TODO rimosso: mostra gli altri pivot vicini sulla mappa
                    /*self.pivotVicini.forEach(el => {
                      self.map.addToMap(el["lat"],el["lng"],Common.safeString(el,["tri:Titolo_"+self.$store.getters.getLanguage,0,"@value"]),el)
                      el.marker.on('click',() => self.selectPivot(el))
                    })*/
                    self.selectPivot(pivot)
                })
              })
            }
            else{
              Common.getElementMedia(self,r.body,mediaList => {
                  self.$store.commit('setPivotImages',mediaList)
                  self.$store.dispatch('setPivotSelezionato',[self,r.body])
                  self.map.initMapAndSetCurrent(self.nearestPivot["lat"],self.nearestPivot["lng"],Common.safeString(self.nearestPivot,["tri:Titolo_"+self.$store.getters.getLanguage,0,"@value"]),self.nearestPivot)
                  self.nearestPivot.marker.on('click',() => self.selectPivot(self.nearestPivot))
                  //TODO
                  /*self.pivotVicini.forEach(el => {
                    self.map.addToMap(el["lat"],el["lng"],Common.safeString(el,["tri:Titolo_"+self.$store.getters.getLanguage,0,"@value"]),el)
                    el.marker.on('click',() => self.selectPivot(el))
                  })*/
                  self.selectPivot(r.body)
              })
            }
        })
      }        
    },
    showError: function(error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          this.positionError = this.$t('permissionDenied')
          break;
        case error.POSITION_UNAVAILABLE:
          this.positionError = this.$t('geoFailed')
          break;
        case error.TIMEOUT:
          this.positionError = this.$t('timeoutGeo')
          break;
        case error.UNKNOWN_ERROR:
          this.positionError = this.$t('unknownGeo')
          break;
      }
    },
    selectPivot: function(elem){
      var self = this;
      //TODO
      /*this.pivotVicini.forEach(el => {
          el.marker.setIcon(this.blackIcon)
          el.marker.setZIndexOffset(0)
          el.marker._icon.classList.remove("huechange")
          el.marker._icon.classList.add("huestd")
      })*/
      if(this.nearestPivot.marker){
        this.nearestPivot.marker.setIcon(this.blackIcon)
        this.nearestPivot.marker.setZIndexOffset(0)
        this.nearestPivot.marker._icon.classList.remove("huechange")
        this.nearestPivot.marker._icon.classList.add("huestd")
      }
      if(elem.hasOwnProperty("marker")){
        elem.marker.setIcon(this.orangeIcon)
        elem.marker.setZIndexOffset(1000)
        elem.marker._icon.classList.add("huechange")
        elem.marker._icon.classList.remove("huestd")
      }
      Common.getElementMedia(this,elem,mediaList => {
          self.$store.commit('setPivotImages',mediaList)
          self.$store.dispatch('setPivotSelezionato',[self,elem])
          self.$router.replace({name: 'magliefrompivot',params:{pivot:elem['o:id']}}).catch(err => {
            if (!isNavigationFailure(err, NavigationFailureType.duplicated))
              self.navigationError = err
          })
      })
    },
    thumbnailSrc: function(elem){return Common.safeString(this.thumbnails,[elem["o:id"],'thumbnail_display_urls','square']);},
    alt: function(elem){return this.$t('imageFor') + Common.safeString(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,'@value'])},
    cardTitle: function(elem){return Common.safeString(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,'@value'])},
    cardBody: function(elem){return Common.safeString(elem,["dcterms:description",0,"@value"]).substr(0,200) + (Common.safeString(elem,["dcterms:description",0,"@value"]).length > 200 ? '...' : '')}
  },
  computed: {
    map: function(){
      return this.$refs.header.$refs.mapPercorso
    },
    allPivots: function(){
      if(this.nearestPivot !== null)
        return this.pivotVicini.concat(this.nearestPivot)
      else
        return this.pivotVicini
    }
  },
  watch: {
    $route (to, from){
      this.onHomePage++
      if(this.$route.name === 'index' && from.name === 'magliefrompivot'){
        this.returning = true
        this.getPosition()
      }
      document.getElementById("mapContainer").style.display = ''
      if(document.getElementById("list")){
        document.getElementById("list").classList.add("forScreenReaders")
      }
    }
  }
};
</script>
<style scoped lang="scss">
.b-card{
  background-color:#fff7e6
}
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@include media-breakpoint-up(md) {
  .cardTextContent{
    height:200px;
    width:200px;
  }
  .b-card{
    margin-left:5%;
    max-width:90%;
  }
}
h3, .centered{
  text-align: center;
}
</style>