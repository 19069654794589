<template>
    <div id="app">
    <!--<router-view name="header" />-->
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
import CookieModal from "./components/CookieModal";
export default {
    mounted () {
        if (localStorage.getItem("acceptCookie") !== "1")
            this.$bvModal.show("cookieModal")
    }
};
</script>
