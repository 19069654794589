/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import vueResource from 'vue-resource';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import App from './App.vue';
import L from 'leaflet';
import Vuex from "vuex";

import LazyLoadDirective from "./directives/lazy-loading";
Vue.directive("lazyload", LazyLoadDirective);


// FIX leaflet's default icon path problems with webpack
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './starterRouter';
import './app.scss';
import NowUiKit from './plugins/now-ui-kit';
import store from './store.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'
import EasyButton from './pages/components/EasyButton'

Vue.config.productionTip = false;

const LanguageDetect = require('languagedetect');

Vue.use(NowUiKit);
Vue.use(vueResource);
Vue.use(L);
Vue.use(Vuex);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('easybutton',EasyButton)

/*window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})


router.beforeEach((to, from, next) => {
  const IsItABackButton = window.popStateDetected
  window.popStateDetected = false
  if (IsItABackButton) {
    if(document.getElementById("backButton")){
      document.getElementById("backButton").click()
      next(false)
    }
    else
      next()
    return ''
  }
  next()
})*/

new Vue({
  router,
  'store':store,
  i18n,
  render: h => h(App)
}).$mount('#app');

// ANGELO Language
// document.documentElement.setAttribute('lang', localStorage.getItem("language") === null ? "it" : localStorage.getItem("language"))
// Fabio Language
document.documentElement.setAttribute('lang', localStorage.getItem("language_sys") === null ? "it" : localStorage.getItem("language_sys"))