
<script>
const Common = require('@/Common.vue').default
import Vue from 'vue';
import Vuex from "vuex";
import store from './store.js'

Vue.use(Vuex);


const MAX_QRCODE_LENGTH = 4;
const MIN_QRCODE_VALUE = 0;
const MAX_QRCODE_VALUE = 9999;
const ID_PROPERTY_LABEL = 'o:id';
const QR_CODE_PROPERTY_LABEL = 'dcterms:isReferencedBy';
const ID_QRCODE_PROPERTY = 35;

export default {

    name: 'qrutils',

    /**
     * Return TRUE if the QR_code string is a valid string, FALSE otherwise
     * @param {String} qrCodeStr the QR_Code string (e.g 0001 or 0002 or 1212 etc)
     */
    isQRCodeValid : function name(qrCodeStr) { 
        if (!((typeof qrCodeStr) === 'string')) return false;
        if (/^\d+$/.test(qrCodeStr)) {
            const id = parseInt(qrCodeStr);
            return  qrCodeStr.length === MAX_QRCODE_LENGTH && !isNaN(id) && 
                    id >= MIN_QRCODE_VALUE && id <= MAX_QRCODE_VALUE;
        }
        return false;
    },

    /**
     * Check the response after call Common.getElemByQRCodeProperty
     * return undefined if the response is not valid or the response is not equal to qrCode, 
     * otherwise return the ID of the item with QR Code === qrCode
     * @param {*} responseElement the response of the OmekaS call
     * @param {*} qrCode the QR Code (String)
     */
    checkResponse : function(responseElement, qrCode) {
        if(!responseElement || responseElement.body.length === 0) return undefined;
        let elem = responseElement.body[0];
        
        if (elem[QR_CODE_PROPERTY_LABEL].length === 0) return undefined;

        let elemID = responseElement.body[0][ID_PROPERTY_LABEL]; 
        let res = undefined; 
        for (let element of elem[QR_CODE_PROPERTY_LABEL]) {
            if (element['@value'] === qrCode && elemID){
                res = elemID;
                break;
            }
        }
        return res;
    },



    /**
     * 
     * @param {*} qrCode the QR Code (String)
     */
    mapQRCodeToElem : async function(qrCode) {
        const vueObjHelper = new Vue({store});        
        let qrParsed = this.isQRCodeValid(qrCode);  
        if (!qrParsed) {
            return undefined;
        } 
        
        let res = null;
        await new Promise(resolve => {            
            Common.getElemByQRCodeProperty(vueObjHelper, ID_QRCODE_PROPERTY, qrCode, (response) => {
                res = this.checkResponse(response, qrCode);    
                resolve();                                                    
            })
        })
        return res;
    }
}

</script>