<template>
    <b-container fluid class="cont">
        <p style="text-align:center" v-if="this.$props.title">{{$props.title}}</p>
        <p style="text-align:center" v-else>{{$t("fonti")}}</p>
        <b-list-group>
            <b-list-group-item v-for="el in this.uniqueEls" :key="elValue(el)" v-html="linkify($props.elValue(el))">
            </b-list-group-item>
        </b-list-group> 
    </b-container>   
</template>
<script>
const Common = require('@/Common.vue').default

export default{
    name:'Sources',
    props: {
      refs : {
          type: Array,
          required: true
      },
      title: {
          type: String,
          required: false,
      },
      elValue: {
          type: Function,
          required: false,
          default: function(el){
              return el["@value"]
          }
      }
    },
    methods: {
        linkify: function(text) {
            var urlRegex =/((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
            return Common.processMarkdown(text).replace(urlRegex, function(url) {
                var url_processed = url.toLowerCase().startsWith("http") ? url : "http://"+url;
                return '<a href="' + url_processed + '" target="_blank">' + url + '</a>';
            });
        },
    },
    computed: {
        uniqueEls: function(){
            return this.$props.refs.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i)
        }
    }
}
</script>
<style scoped>
.cont{
    margin-left:0px;
    margin-right:0px;
    margin-bottom:3%;
    padding-left:0px;
    padding-right:0px;
    overflow:hidden;
    word-break: break-all;
}
h5{
    text-align: center;
}
</style>

