<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
        <b-row class="mx-2 justify-content-between">
            <b-col lg="col-1">
                <label>
                    <a href="/privacy.html">Privacy</a>
                </label>
            </b-col>
            <b-col lg="col-3">
                <label>
                    Powered by
                    <a href="http://www.di.unito.it/do/home.pl">
                        Dipartimento di Informatica - UniTo
                    </a>
                </label>
            </b-col>
            <b-col lg="col-2">
              <label>
                <a href="/credits.html">
                  Credits
                </a>
              </label>
            </b-col>
            <b-col  lg="col-4">
                <label>
                    Template:
                    &copy; {{ year }}, Designed by
                    <a href="https:///www.invisionapp.com"
                       target="_blank" rel="noopener">Invision</a>.
                    Coded by
                    <a href="https://binarcode.com"
                       target="_blank" rel="noopener">BinarCode</a>
                    and
                    <a href="https://www.creative-tim.com" target="_blank" rel="noopener"
                    >Creative Tim</a>.
                </label>
            </b-col>
          <b-col lg="col-2">
            <label @click="openAuth()" v-if="(this.$store.getters.getPw !== admin && this.auth_now !== 1) || this.auth_now === -1">{{$t('adminRights')}}</label>
            <label @click="removeAuth()" v-if="(this.$store.getters.getPw === admin && this.auth_now !== -1) || this.auth_now === 1">{{$t('userRights')}}</label>
          </b-col>
        </b-row>
    </div>
  </footer>
</template>
<script>
import Swal from "sweetalert2";
const Common = require('@/Common.vue').default

export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      auth_now: 0
    };
  },
  computed: {
    admin: {
      get () {
        return localStorage.getItem("admin")
      },
      set (value) {
        localStorage.setItem("admin",value)
        this.auth_now = 1
      }
    }
  },
  methods:{
    openAuth(){
      Swal.fire({
        title: this.$t("pwPrompt"),
        html: `<input type="password" id="password" class="swal2-input" placeholder="Password">`,
        confirmButtonText: this.$t("conferma"),
        focusConfirm: false,
        preConfirm: () => {
          const password = Swal.getPopup().querySelector('#password').value
          if (!password) {
            Swal.showValidationMessage(this.$t("pwPrompt"))
          }
          else if(this.$store.getters.getPw !== password){
            Swal.showValidationMessage(this.$t("wrongPw"))
          }
          return {password: password }
        },
        showCloseButton: true
      }).then((result) => {
        this.admin = result.value.password
        this.$refs.navb.admin = this.admin
      })
    },
    removeAuth(){
      localStorage.removeItem("admin")
      this.auth_now = -1
      this.$refs.navb.admin = null
      this.$store.commit('clearStorage')
    }
  }
};
</script>
<style scoped>
#cont{
  margin-top:100px;
  margin-bottom:30px
}
h3, h3{
  text-align:center;
}
#closeIcon{
  opacity: 0.5;
}

#closeIcon:hover{
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}
</style>
<style>
.swal2-confirm{
  color:black !important;
  background-color: #FFAB4C !important;
}
</style>