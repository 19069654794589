<template>
  <navbar position="fixed" type="primary" menu-classes="ml-auto" id="mainNav">
    <template>
      <router-link class="navbar-brand" :to="{name: 'seeAllStories'}"  style="font-size: 1.8em">
        <img src="/favicon.png" style="width:1.5em; max-height:1.5em;">
        Triangolazioni
      </router-link>
    </template>
    <template slot="navbar-menu">
        <!-- <li class="nav-item">
          <b-dropdown id="nav-btn-lang" text="IT" variant="primary" class="m-2 nav-link">
            <b-dropdown-item href="#">Action</b-dropdown-item>
            <b-dropdown-item href="#">Another action</b-dropdown-item>
          </b-dropdown>
        </li> -->
        <li class="nav-item">
          <b-dropdown id="nav-btn-lang" variant="primary" class="m-2 nav-link">
            <template #button-content>    
              <span class="mr-3">
                <country-flag :country=getFlag size="normal" />
              </span>
              <p style="font-size: 1.2em !important; text-transform: uppercase;">{{language_sys}}</p>
            </template>
            <b-dropdown-item @click="onChangeLanguage('it')">
              <div>
                <p style="font-size: 1em !important" class="mx-2">Italiano</p>
                <country-flag country="it" size="small" />
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="onChangeLanguage('en')">
              <div>
                <p style="font-size: 1em !important" class="mx-2">English</p>
                <country-flag country="gb" size="small" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </li>


    </template>
  </navbar>
</template>

<script>
import {Navbar } from '@/components';
import {DropDown} from '@/components'
import CountryFlag from 'vue-country-flag'
import Swal from 'sweetalert2'
import { mapState } from 'vuex';

export default {
  name: 'main-navbar',
  components: {
    Navbar,
    CountryFlag
  },
  data(){
    return {
      admin: localStorage.getItem("admin"),
      current_flag : undefined,
    }
  },
  mounted() {
    this.current_flag = this.getFlag
  },
  
  computed: {
    getFlag() {
      return this.language_sys === 'en' ? 'gb' : 'it'
    },
    ...mapState(['language_sys'])
  },
  methods: {
    onChangeLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('updateLanguageSys', lang) 
    },
    onMobile: function(){
          return window.innerWidth < 768
    },
    back(){
            /*if(this.$parent.hasOwnProperty("back"))
                this.$parent.back();
            else
                this.$router.go(-1);*/
            Swal.fire({
                html: "<h3 class='customH3'>"+this.$t("percorsiPrec")+"</h3>" + this.generateHtml(),
                confirmButtonText: this.$t("chiudi"),
            })
            this.makeClickable()
        },
        makeClickable(){
            var self = this
            window.setTimeout(() =>{
                for(const el in self.$store.getters.getmaglieHistory){
                    document.getElementById('history_'+el).addEventListener("click", function() {
                        self.$router.push({path:self.$store.getters.getmaglieHistory[el][0]})
                        if(self.$parent && self.$parent.$parent && self.$parent.$parent.back()){
                          self.$parent.$parent.back()
                        }
                        Swal.close()
                    })
                }
            },500)
        },
        generateHtml: function(){
            var visited_parsed = this.$store.getters.getmaglieHistory
            var html = ""
            for(const el in visited_parsed){
                html += "<p id='history_"+el+"' class='defaultLink'>"+visited_parsed[el][1]+"</p>"
            }
            if(html === ""){
              html = this.$t("noHistory")
            }
            return html
        },
  },
};
</script>
<style scoped>
.navbar-brand, p, .nav-item{
  font-size: 1.78209em;
}
</style>
<style>
.swal2-confirm{
    color:black !important;
    background-color: #FFAB4C !important;
}
.defaultLink:hover{
    color: #0056b3;
    text-decoration: underline;
    cursor:pointer;
}
.defaultLink{
    color: #0056b3;
}
@media only screen and (max-width: 768px){
  .swal2-confirm{
    font-size:14px !important
  }
}
@media only screen and (min-width: 768px){
  .swal2-confirm{
    font-size:15.6px !important
  }
}
.swal2-actions{
  margin-top:2px;
}
.swal2-html-container{
  font-size:12px !important;
}
.customH3{
  color: #2c2c2c
}
.nav-item{
  display: inline-block;
}
#nav-btn-lang {
  margin: 0 1rem !important;
  padding: 0 !important;
  font-size: inherit !important;
  background-color: transparent !important;
}
#nav-btn-lang button{
  margin: 0 !important;
  /* padding: inherit !important; */
  padding: 6px 12px;
  background-color: transparent !important;
}
</style>