<template>
    <div>
        <starter-navbar :colorOnScroll="400"></starter-navbar>
        <b-container fluid 
            v-show="!this.showM && this.imgObj !== null && this.imgObj.length > 0">
            <figure v-if="this.imgObj !== null">
                <Media
                    :media="this.imgObj"
                    :handleText="false"
                />
            </figure>
        </b-container>
        <div :class="this.$props.margin0 ? '' : 'mapCont'" v-show="this.showM">
            <figure aria-hidden="true">
                <Maps ref="mapPercorso" :map-height="mapHeight" :showListButton="this.$props.showListButton"/>
            </figure>
        </div>
    </div>
</template>
<script>
import StarterNavbar from '../../layout/StarterNavbar.vue';
import Maps from './Maps.vue'
import Swal from 'sweetalert2'
import Media from './Media.vue'
const Common = require('@/Common.vue').default


export default {
    name: 'Header',
    props: {
        showMap : {
            type: Boolean,
            required: false,
            default: false
        },
        showListButton: {
            type: Function,
            required: false
        },
        margin0: {
            type: Boolean,
            default: false
        },
        mapHeight: {
          type: String,
          default: '300px'
        }
    },
    data: function(){
        return {
            showM : this.$props.showMap,
            imgObj : null
        }
    },
    methods: {
        updateImg: function(imgObj = null){
            this.imgObj = imgObj
            this.showM = this.showM || (this.imgObj == null || this.imgObj.length === 0)
        },
        s: function(e,a,d=null){
            return Common.safeString(e,a,d)
        },
    },
    computed: {
        title: function(){
            return this.$parent.title
        },
        favTitle: function(){
            /* We need this in order to use the actual favTitle, as the hierarchy is
                Actual_Page > Header > FavouritesButton.
            */
            return this.$parent.favTitle
        },
    },
    components: {StarterNavbar, Maps, Media}
}
</script>
<style scoped>
.mapCont{
    margin-top: 90px;
}
.forScreenReaders { 
    border: 0; 
    clip: rect(0 0 0 0); 
    height: 1px; 
    margin: -1px; 
    overflow: hidden; 
    padding: 0; 
    position: absolute; 
    width: 1px; 
}
</style>