<template>
    <b-container fluid style="padding-left:0; padding-right: 0;">
        <b-card no-body :class="cardClasses"  v-for="(elem,index) in sortedElems" :key="elem['o:id']" @click="logAndHandle(elem)">
            <article>
              <b-row no-gutters :style="'margin-left:0px; margin-right:30px; height:auto; min-height:7em;'">
                <b-col :cols="onDesktop ? 2 : 3" class="imgCol" v-if="$props.thumbnail(elem)">
                    <b-card-img class="cardImg rounded-0" :data-url="$props.thumbnail(elem)" :alt="$props.alt(elem)" v-lazyload></b-card-img>
                </b-col>
                <b-col class="cardTextContent" :cols="onDesktop ? 10 : 8">
                    <b-card-body class="cardBody" :title='onDesktop || $props.alwaysOnDesktop ? $props.title(elem) : ""'>
                    <b-card-text v-if="onDesktop || $props.alwaysOnDesktop">
                        <span v-html="md($props.body(elem))" aria-hidden="true"></span>
                        <a class="sr-only">{{$props.body(elem)}}</a>
                        <span v-for="action in $props.actions" :key="action.text">
                            <n-button type="primary" style="padding-top:0.25em; padding-bottom:0.25em" @click="action.handle(elem,index)">
                              {{action.text}}
                            </n-button>
                        </span>
                    </b-card-text>
                    <b-card-text v-else style="height:100%">
                      <div class="align" v-html="md($props.title(elem))" aria-hidden="true">
                      </div>
                      <a class="sr-only">{{$props.title(elem)}}</a>
                    </b-card-text>
                    </b-card-body>
                </b-col>
                <b-col v-if="!$props.alwaysOnDesktop && !onDesktop" cols="1" aria-hidden="true">
                  <b-icon-arrow-right-circle scale="2" class="arrowRightIcon"></b-icon-arrow-right-circle>
                </b-col>
              </b-row>
              <b-icon-arrow-right-circle scale="2" class="arrowRightIcon" v-if="!$props.alwaysOnDesktop && onDesktop" aria-hidden="true"></b-icon-arrow-right-circle>
            </article>
      </b-card>
    </b-container>
</template>
<script>
const Common = require('@/Common.vue').default
import {Button} from '@/components'
import {
  hyphenateSync
} from "hyphen/it";

export default {
    components: { [Button.name]: Button },
    name: 'CardList',
    props: {
      elems : {
          type: Array,
          required: true
      },
      handler: {
        type: Function,
        required: true
      },
      thumbnail: {
        type: Function,
        required: true
      },
      alt: {
        type: Function,
        required: true
      },
      title: {
        type: Function,
        required: true
      },
      body: {
        type: Function,
        required: true
      },
      alwaysOnDesktop : {
        type: Boolean,
        default: false
      },
      actions: {
        type: Array,
        required: false
      },
      pointerChange: {
        type: Boolean,
        default: true
      },
      listTitle: {
        type: String,
        required: true
      }
    },
    computed: {
      onDesktop: function(){
        return window.innerWidth >= 768
      },
      cardClasses: function(){
        var classes = 'overflow-hidden b-card h-100' 
        if(this.$props.pointerChange)
          classes += ' b-cardPtr'
        return classes
      },
      sortedElems: function(){
        return this.sortList(this.$props.elems)
      }
    },
    methods: {
      logAndHandle: function(elem){
        const self = this
        Common.addUserStudyLog(this, 'CardClicked', {
          'listTitle': self.$props.listTitle,
          'cardTitle': self.$props.title(elem)
        })
        this.$props.handler(elem);
      },
      md: (st) => hyphenateSync(Common.removeMarkdown(st)),
      sortList: function(list){
        return list.sort(( a, b ) => {
            var aVal,bVal

            if(Common.safeString(a,["item","dcterms:tableOfContents",0,"@value"]) === ""){
              aVal = parseInt(Common.safeString(a,["dcterms:tableOfContents",0,"@value"]))
              bVal = parseInt(Common.safeString(b,["dcterms:tableOfContents",0,"@value"]))
            }
            else{
              aVal = parseInt(Common.safeString(a,["item","dcterms:tableOfContents",0,"@value"]))
              bVal = parseInt(Common.safeString(b,["item","dcterms:tableOfContents",0,"@value"]))
            }

            if(isNaN(aVal) && isNaN(bVal)){return 0}
            else if(isNaN(aVal)){return 1}
            else if(isNaN(bVal)){return -1}
            else if (aVal < bVal){return -1}
            else if ( aVal > bVal ){return 1}
            else{return 0}
        })
      }
    }
}
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@include media-breakpoint-up(md) {
  .card-title{
    margin-top:2%;
    margin-bottom:2%;
  }
  .cardBody{
    padding-bottom:1%
  }
  .cardImg{
    position:absolute;
  }
  .arrowRightIcon{
    right: 15px !important;
  }
}
.cardImg{
  object-fit:cover;
  height:100%;
}
.cardBody{
  padding-top:0;
}
.b-card{
  margin-bottom:1%;
  transition: box-shadow .3s;
}
.b-cardPtr{
  cursor:pointer;
}
.b-card:hover{
  box-shadow: 0 0 10px #000000; 
}
.align {
  position:absolute;
  top:50%; 
  transform: translateY(-50%); 
}
.arrowRightIcon{
  position: absolute;
  top: 50%;
  right:-15px;
  transform: translateY(-50%);
}
</style>
