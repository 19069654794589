<template>
    <b-container class="centeredButton" id="mapContainer">
        <span v-if="this.map=== null || changing">
            <b-skeleton v-for="index in 10" :key="index"></b-skeleton>
        </span>
        <div id="map" v-show="!changing" :style="'height:'+$props.mapHeight"></div>
    </b-container>
</template>
<script>
import {Button} from '@/components'
import 'leaflet-geometryutil';

const Common = require('@/Common.vue').default
export default {
    name: 'maps',
    data: function(){
        return {
            map : null,
            layers: [],
            changing: false,
            enqueued: []
        }
    },
    props: {
        showListButton: {
            type: Function,
            required: false
        },
        mapHeight: {
          type: String,
          default: '300px'
        }
    },
    components: {[Button.name]: Button},
    methods:{
        s(unsafeString,chain,debugging_label = null){
            return Common.safeString(unsafeString,chain,debugging_label)
        },
        /**
         * Initialize the map
         */
        initMap(lat=0,lng=0){
            if(this.map){
                this.map.panTo(L.latLng(lat,lng))
                return
            }
            this.osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            });
            this.map = L.map('map', {
                center: [lat, lng],
                zoom: 13,
                zoomControl: false,
                preferCanvas: !!document.createElement('canvas').getContext,
                layers: [this.osm]
            });

            var stamen = L.tileLayer('https://stamen-tiles.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png', {
                attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                subdomains: 'abcd',
                minZoom: 0,
                maxZoom: 20
            });
            var base = {
                OpenStreetMap: this.osm,
                'Stamen Toner-Lite': stamen
            };
            var overlay = {};
            L.control.layers(base, overlay).addTo(this.map);

            L.control.zoom({
                position: 'topright'
            }).addTo(this.map);

            L.control.scale({
                position: 'bottomright',
                metric: true,
                imperial: true,
                updateWhenIdle: true
            }).addTo(this.map);

            var self = this
            this.map.on('click', (e) => {
                var polylines = []
                self.map.eachLayer(el => {
                    if(el instanceof L.Polyline)
                        polylines.push(el)                
                })
                var clicked = self.map.mouseEventToLatLng(e.originalEvent)
                var closest = L.GeometryUtil.closestLayerSnap(self.map,polylines,clicked,25)
                if(closest){
                    var latLngs = closest.layer.getLatLngs();
                    var m1 = self.getDistance(latLngs[0], clicked);
                    var m2 = self.getDistance(latLngs[1], clicked);
                    if(m1 < m2) self.map.panTo(latLngs[1])
                    else self.map.panTo(latLngs[0])
                }
            })


            if(this.$props.showListButton){
                L.easyButton( '<i class="now-ui-icons design_bullet-list-67"></i>', this.$props.showListButton,{position: 'topright'}).addTo(this.map);
            }

            var svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" version="1.1" viewBox="-12 -12 24 24"><circle r="5" style="stroke:#fff;stroke-width:1;fill:#2A93EE;fill-opacity:1;opacity:1;"></circle></svg>';

            var iconObj = L.divIcon({
                html : svgIcon
            })
            if(this.$store.getters.getLat === 0 && this.$store.getters.getLng === 0){
              if (navigator.geolocation) {
                var self = this
                /*navigator.geolocation.getCurrentPosition(function(position){
                  self.$store.commit('lat', position.coords.latitude)
                  self.$store.commit('lng', position.coords.longitude)
                  L.marker(L.latLng(position.coords.latitude,position.coords.longitude), {icon: iconObj}).addTo(self.map)
                },this.showError);*/
              }
            }
            else {
              L.marker(L.latLng(this.$store.getters.getLat, this.$store.getters.getLng), {icon: iconObj}).addTo(this.map)
            }
          },
        /**
         * Initialize the map with the current element.
         * @param {number} lat
         * @param {number} lng
         * @param {String} info: info to display in the marker's popup
         * @param {Object} appendToObj=null: The corresponding object in the maglia
         */
        initMapAndSetCurrent(lat,lng,info=null,appendToObj=null){
            var self = this
            if(this.changing){
                this.enqueued.push(function(){self.initMapAndSetCurrentInternal(lat,lng,info,appendToObj)})
            }
            else{
                this.initMapAndSetCurrentInternal(lat,lng,info,appendToObj)
            }
        },
        initMapAndSetCurrentInternal (lat,lng, info = null, appendToObj = null) {
            var self = this
            if(this.map)
                this.map.panTo(L.latLng(lat,lng))
            else
                this.initMap(lat,lng);
            var orange = new L.Icon({
                iconUrl: 'https://exptriangolazioni.ontomap.eu/out2.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [27,34],
                iconAnchor: [13,34.5],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });
            var marker = L.marker(L.latLng(lat, lng),{icon:orange});
            marker.addTo(this.map)
            this.layers.push(marker)
            marker._icon.classList.remove("huestd");
            marker._icon.classList.add("huechange");
            if(info !== null && self.$parent.$parent.changeItem){
                var content = L.DomUtil.create('span','popupContent')
                var popup = L.popup().setContent(content);
                content.innerHTML = "<p style='text-align:center !important'>"+info+"<br><a class='stdLink'>" + this.$t("esplora") + "</a></p>"
                L.DomEvent.addListener(content, 'click', function(event){
                   self.$parent.$parent.changeItem(appendToObj)
                   self.map.closePopup()
                });
                marker.bindPopup(popup);
            }
            if(appendToObj !== null){
                appendToObj["marker"] = marker;
            }
        },
        /**
         * Adds an element to the map.
         * @param {number} lat
         * @param {number} lng
         * @param {String} info=null: The information to display in the popup associated to the marker.
         * @param {Object} appendToObj=null: The corresponding object in the maglia
        */
        addToMap(lat,lng,info=null,appendToObj=null){
            var self = this
            if(this.changing){
                this.enqueued.push(function(){self.addToMapInternal(lat,lng,info,appendToObj);})
            }
            else{
                //console.log("INSTANTLY ADDED TO MAP: " + info)
                this.addToMapInternal(lat,lng,info,appendToObj)
            }
        },
        addToMapInternal(lat,lng,info=null,appendToObj=null){
            var self = this
            if(!this.map)
                return window.setTimeout(() => self.addToMap(lat,lng,info,appendToObj),150)
            var black = new L.Icon({
                iconUrl: 'https://exptriangolazioni.ontomap.eu/output-onlinepngtools_others.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [27,34],
                iconAnchor: [13,34.5],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });
            var marker = L.marker(L.latLng(lat,lng),{icon:black})
            marker.addTo(this.map);
            this.layers.push(marker)
            if(info !== null && self.$parent.$parent.changeItem){
                var content = L.DomUtil.create('span','popupContent')
                var popup = L.popup().setContent(content);
                content.innerHTML = "<p style='text-align:center !important'>"+info+"<br><a class='stdLink'>" + this.$t("esplora") + "</a></p>"
                L.DomEvent.addListener(content, 'click', function(event){
                   self.$parent.$parent.changeItem(appendToObj)
                   self.map.closePopup()
                });
                marker.bindPopup(popup);
            }
            if(appendToObj != null){
                appendToObj["marker"] = marker;
            }
            marker._icon.classList.add("huestd");
            return marker
        },
        /**
         * Adds a link between two markers to the map.
         * @param {any} marker1
         * @param {any} marker2
         * @param {boolean} dashed: true iff the line should be dashed.
         */
        linkMarkers(marker1,marker2,dashed){
            var self = this
            if(this.changing){
                this.enqueued.push(function(){self.linkMarkersInternal(marker1,marker2,dashed)})
            }
            else{
                this.linkMarkersInternal(marker1,marker2,dashed)
            }
        },
        linkMarkersInternal(marker1, marker2, dashed){
            var latlngs = Array();

            //Get latlng from first marker
            latlngs.push(marker1);//.getLatLng());

            //Get latlng from first marker
            latlngs.push(marker2);//.getLatLng());
            var style;
            if(dashed){
                style = {color: '#45567C', dashArray: '7, 7', dashOffset: '0'};
            }
            else{
                style = {color: '#45567C'};
            }
            var line = L.polyline(latlngs, style)
            line.addTo(this.map)
            this.layers.push(line)
            //console.log(this.map.almostOver)            
            //this.map.almostOver.addLayer(link)
        },

        operationOnMarker(operation){
            if(this.changing){
                this.enqueued.push(operation)
            }
            else{
                operation()
            }
        },

        pan(marker){
            this.map.panTo(marker);
        },
        
        reset(){
            this.changing = true
            /*this.map.remove();
            this.map = null*/
            for(var i = 0; i < this.layers.length; i++){
                this.map.removeLayer(this.layers[i]);
            }
        },

        applyChanges(){
            var self = this
            for(var j = 0; j < self.enqueued.length; j++){
                self.enqueued[j]();
            }
            self.enqueued = []
            self.changing = false
        },

        getDistance: function(from, to){
            return from.distanceTo(to)
        },
    }
}
</script>
<style>
img.huestd{filter: invert(31%) sepia(15%) saturate(1345%) hue-rotate(183deg) brightness(97%) contrast(89%);}
.stdLink{
    color:#007bff !important;
}
.stdLink:hover{
    text-decoration: underline !important;
    cursor:pointer !important;
}
.leaflet-div-icon{
    border: 0;
    background-color:rgba(0, 0, 0, 0);
}
</style>
<style scoped>
.centeredButton{
    text-align:center
}
</style>