import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  // ANGELO Language
  // locale: localStorage.getItem("language") === null ? "it" : localStorage.getItem("language"),
  // fallbackLocale: localStorage.getItem("language") === null ? "it" : localStorage.getItem("language"),
  // messages: loadLocaleMessages()
  // ANGELO Language
  // ------------------------------
  // FABIO Language
  locale: localStorage.getItem("language_sys") === null ? "en" : localStorage.getItem("language_sys"),
  fallbackLocale: localStorage.getItem("language_sys") === null ? "en" : localStorage.getItem("language_sys"),
  messages: loadLocaleMessages()
  // FABIO Language
})
