<template>
    <b-modal  id="cookieModal" size="xl" ok-only @ok="acceptCookie" no-close-on-backdrop :ok-title="$t('inizia')">
            <ul class="nav nav-tabs border-bottom" role="tablist" id="tab-lang">
            <li role="presentation" id="label_ita_li">
                <b-button @click="setLanguage('it')" v-bind:class="{'languageactive' : this.current_lang === 'it'}"
                 id="label_ita" role="tab" data-toggle="tab">Italiano <country-flag country='it' size='small'/></b-button>            
            </li>
            <li role="presentation" id="label_eng_li">
                <b-button @click="setLanguage('en')" v-bind:class="{'languageactive' : this.current_lang === 'en'}"
                id="label_eng" role="tab" data-toggle="tab">English <country-flag country='gb' size='small'/></b-button>            
            </li>
       </ul>
        
        <!-- <div style="font-size:180px">
            <flag iso="it" />
        </div> -->
        <!-- <b-tabs content-class="mt-3">
            <b-tab title="Italiano" active></b-tab>
            <b-tab title="English"><p>I'm the second tab</p></b-tab>
        </b-tabs> -->
        <template #modal-header> <h3>{{$t('inizio')}}</h3></template>
        <p v-html="$t('privacy')[0]"></p>
        <p class="border p-1 rounded border-dark" v-html="$t('privacy')[1]"></p>
        <p v-html="$t('privacy')[2]"></p>
    </b-modal>
</template>

<script>
import CountryFlag from 'vue-country-flag'
export default {
    name: "CookieModal",
    components : {
        CountryFlag
    },
    data () {
      return {
          canBeClosed: false,
          current_lang : undefined
      }
    },
    computed: {
    },
    methods: {
        acceptCookie () {
            localStorage.setItem("acceptCookie","1")
        },
        setLanguage(new_lan) {
            this.$i18n.locale = new_lan
            this.$store.commit('updateLanguageSys', new_lan) 
            this.current_lang = new_lan
        }
    },
    mounted() {
        this.current_lang = this.$store.getters.getLanguageSys;
    },
}
</script>

<style>
#tab-lang {
    padding: 0;
    margin-bottom: 2%;
    display: flex;
}
#tab-lang #label_ita_li #label_ita,  #tab-lang #label_eng_li #label_eng{
    color: black;
    font-size: small;
    background-color: whitesmoke;
    text-align: center;
    align-items: center;
    display: flex;
    /* padding: 2%; */
}
.languageactive {
    font-weight: bold !important;    
    border-color: rgb(0, 0, 0) !important;
    text-decoration: underline !important;
}
</style>