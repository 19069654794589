<template>
    <div>
        <div v-if="this.$props.txt">
            {{this.$props.txt}}
        </div>
        <div id="toast_wrapper">
            <b-toast id="toast" static auto-hide-delay="1000" no-close-button no-hover-pause>
                {{togglePauseMessage}}
            </b-toast>
        </div>
        <b-row
            class="row"
            align-h="center"
        >
        <!--
            <CardList
                :elems="this.videos" 
                :handler="this.seeVideo"
                :thumbnail="this.thumbnailSrc"
                :alt="this.alt"
                :title="this.cardTitle"
                :body="this.cardBody"
            />
        -->
            <figure>
                <iframe frameborder="0" :src="videoSource(video)" allowfullscreen v-for="video in this.videos" :key="video['o:id']" style="padding-bottom:3%;"></iframe>
            </figure>
        </b-row>
        <figure v-if="!(this.$props.videosOnly && this.videos.length > 0)">
            <b-row 
                align-h="center"
                class="row"
                v-if="this.images.length > 1"
            >
                <b-carousel
                    v-model="slide"
                    id="carousel-1"
                    :interval="0"
                    indicators
                    controls
                    style="text-shadow: 1px 1px 2px #333; color:red"
                >
                    <b-carousel-slide
                        v-for="(media_element,index) in this.images"
                        :key="media_element['o:id']"
                        :text-html="'<span style=\'font-size:0.8em;\'>' +description(index) + '</span>'"
                    >
                        <img slot="img" class="d-block img-fluid carouselImage"
                                style="max-height:50vh"
                                :alt="s(media_element,['o:title']).split('_').pop()"
                                :src="s(media_element,['thumbnail_display_urls','large'])"
                                @click="openImage(media_element)"
                            >
                    </b-carousel-slide>
                </b-carousel>
            </b-row>
            <b-row 
                align-h="center"
                class="row"
                v-else-if="this.images.length > 0"
            >
                <b-carousel
                    v-model="slide"
                    id="carousel-1"
                    :interval="0"
                    style="text-shadow: 1px 1px 2px #333;"
                >
                    <b-carousel-slide
                        v-for="(media_element,index) in this.images"
                        :key="media_element['o:id']"
                        :text-html="'<span style=\'font-size:0.8em;\'>' +description(index) + '</span>'"
                    >
                        <img slot="img" class="d-block img-fluid carouselImage"
                                style="max-height:50vh"
                                :alt="s(media_element,['o:title']).split('_').pop()"
                                :src="s(media_element,['thumbnail_display_urls','large'])"
                                @click="openImage(media_element)"
                            >
                    </b-carousel-slide>
                </b-carousel>
            </b-row>
        </figure>
        <b-row class="row" style="margin-top:3%" v-if="this.$props.handleText && this.text.length > 0">
            <Sources 
                :refs="this.text"
                :title="this.$t('altreRis')"
                :elValue="this.elValue"
            />
        </b-row>
    </div>
</template>
<script>
const Common = require('@/Common.vue').default
import Sources from './Sources.vue'
import Swal from 'sweetalert2'
//import CardList from './CardList.vue'
export default{
    data: function(){
        return {
            slide: 0,
            interval: 5000,
            togglePauseMessage: this.$t("pausa"),
        }
    },
    components: {Sources, /*CardList*/},
    props:{
        txt: {
            type: String,
            required: false
        },
        media: {
            type: Array,
            required: true
        },
        handleText: {
            type: Boolean,
            default: true
        },
        videosOnly: { // If true, only display videos (if present). If videosOnly is true but there are only images, they are displayed anyway.
            type: Boolean,
            default: false
        }
    },
    updated: function(){
        if(this.slide >= this.images.length)
            this.slide = 0
    },
    methods: {
        s: function(e,a,d=null){
            return Common.safeString(e,a,d)
        },
        openVideo: function(media_element){
            window.open(media_element['o:source'], '_blank').focus();
        },
        openImage : function(media_element){
            this.interval = 0
            var self = this
            Swal.fire({
                html: "<h3 style='font-size:1em !important'>" + self.s(media_element,['o:title']).split('_').pop()+'</h3><img\
                        style="margin:0; height:auto; width:100%;"\
                        alt="'+self.s(media_element,['o:title']).split('_').pop()+'"\
                        src="'+self.s(media_element,['thumbnail_display_urls','large'])+'"\
                       />',
                confirmButtonText: self.$t("chiudi"),
                confirmButtonColor: '#979797',
            }).then((res) => {
                self.slide++
                self.interval = 5000
            })
            document.getElementById("swal2-html-container").style.marginLeft = "0px"
            document.getElementById("swal2-html-container").style.marginRight = "0px"
        },
        toggleInterval: function(e){
            if(this.interval === 0){
                this.interval = 5000
                this.togglePauseMessage = this.$t("play")
                this.slide++
            }
            else{
                this.interval = 0
                this.togglePauseMessage = this.$t("pausa")
            }
            var toast = document.getElementById('toast_wrapper')
            var coords = {
                x: e.clientX + window.scrollX,
                y: e.clientY + window.scrollY
            }
            toast.style.left = coords.x + 'px'
            toast.style.top = coords.y + 'px'
            this.$bvToast.show('toast')
        },
        elValue: function(el){
            return Common.safeString(el,["o:title"]) + " " + Common.safeString(el,["o:source"])
        },
        videoSource: function(media_element){
            if(media_element['o:source'].includes("youtu.be/"))
                return "https://youtube.com/embed/"+media_element['o:source'].split("/").pop()+"?modestbranding=1&playsinline=1&rel=0"
            else
                return "https://youtube.com/embed/"+media_element['o:source'].split("/watch?v=").pop()+"?modestbranding=1&playsinline=1&rel=0"
        },
        thumbnailSrc: (el) => '../img/play-btn-fill.svg',
        alt: function(elem){return Common.safeString(elem,["dcterms:title",0,"@value"]).split("_").pop()},
        cardTitle: function(elem){return Common.safeString(elem,["dcterms:title",0,"@value"]).split("_").pop()},
        cardBody: (el) => "",
        seeVideo: function(el){
            var self = this
            Swal.fire({
                title: this.cardTitle(el),
                html: '<div class="embed-responsive embed-responsive-16by9">\
                        <iframe class="embed-responsive-item" src="'+this.videoSource(el)+'" allowfullscreen></iframe>\
                      </div>',
                confirmButtonText: self.$t("chiudi"),
                confirmButtonColor: '#f96332',
            })
            document.getElementById("swal2-html-container").style.marginLeft = "0px"
            document.getElementById("swal2-html-container").style.marginRight = "0px"
        },
        description: function(slide){
            if(this.images[slide]){
                return Common.safeString(this.images[slide],["dcterms:title",0,"@value"],this.$props.media).split("_").pop()
            }
            else return ""
        },
    },
    computed: {
        isAuto : function(){
            return window.innerWidth < 768 ? "auto" : ""
        },
        onMobile: function(){
            return window.innerWidth < 768
        },
        images: function(){
            if(!this.$props.media)
                return []
            return this.$props.media.filter(el => el["o:media_type"] && el["o:media_type"].split("/")[0] === 'image');
        },
        videos: function(){
            if(!this.$props.media)
                return []
            var videos = this.$props.media.filter(el => el["o:ingester"] === "youtube");
            var main_video = Common.getMainVideo(videos);
            videos.sort(function(x,y){ return x == main_video ? -1 : y == main_video ? 1 : 0; });
            return videos
        },
        text: function(){
            if(!this.$props.media)
                return []
            return this.$props.media.filter(el => el["o:media_type"] === 'text/html');
        },
    }
}
</script>
<style>
.row{
    margin-left:0.5%;
    margin-right:0.5%;
}
#toast, #toast_wrapper{
    position:absolute;
}
.blockquote{
    margin-top:3%;
    margin-left:0.5%;
    margin-right:0.5%;
    color:black;
}
@media only screen and (max-width: 768px){
    .carouselImage{
        height: 25vh;
        width:100%;
    }
    #carousel-1 {
        width:75%;
    }
    .carousel-control-prev{
        margin-left:-50px;
    }
    .carousel-control-next {
        margin-right: -50px;
    }
}
@media only screen and (min-width: 768px){
    .carouselImage{
        height: 50vh;
        width:100%;
    }
    #carousel-1 {
        width:50%;
    }
    .carousel-control-prev{
        margin-left:-100px;
    }
    .carousel-control-next {
        margin-right: -100px;
    }
}
img {
    object-fit: cover;
}
#carousel-1 {
    text-shadow: 1px 1px 2px #333;
}
.carousel-caption{
    right: 0% !important;
    left: 0% !important;
    bottom: 0% !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.80) 70%, rgba(255, 255, 255, 0) 100%) !important;
    padding-bottom:5px !important;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
@media only screen and (min-width: 640px){
    iframe{
        width: 640px;
        height:360px;
    }
}
@media only screen and (max-width: 640px){
    iframe{
        width: 100%;
        aspect-ratio:64/36;
    }
}
.carousel-indicators{
    margin-bottom:5px;
}
</style>