import Vue from 'vue';
import Router from 'vue-router';
import Starter from './pages/Chooser.vue';
import StarterNavbar from './layout/StarterNavbar.vue';
import StarterFooter from './layout/StarterFooter.vue';

const QRUtils = require('@/QRUtils.vue').default


const Common = require('@/Common.vue').default

Vue.use(Router);


export default new Router({
  mode : "history",
  routes: [
    {
      path: '/maglie-pivot',
      name: 'index',
      components: {
        default: Starter,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      children: [
        {
          path : "/maglie-pivot/:pivot",
          name : "magliefrompivot",
          props : (route) => {
            const pivotId = Number.parseInt(route.params.pivot, 10)
            if (Number.isNaN(pivotId)) {
              return {"pivotId": 0}
            }
            return { "pivotId": pivotId}
          },
          component: () =>
                import(/*webpackChunkName: "magliefrompivot"*/ "./pages/MaglieFromPivot.vue"),
        },
      ]
    },
    {
      path : "/percorso/:percorso",
      name : "percorso",
      alias: "/maglia/:percorso",
      props : (route) => {
        
        const percorsoId = Number.parseInt(route.params.percorso, 10)
        
        if (Number.isNaN(percorsoId)) {
          return 0
        }
        return { "percorso": percorsoId }
      },
      component: () =>
            import(/*webpackChunkName: "maglia"*/ "./pages/Percorso.vue"),
    },
    {
      path : "/impostazioni",
      name : "impostazioni",
      component: () =>
            import(/*webpackChunkName: "settings"*/ "./pages/Settings.vue"),
    },
    {
      path : "/start",
      name : "inizia",
      component: () =>
          import(/*webpackChunkName: "settings"*/ "./pages/Inizia.vue"),
    },
    {
      path : "/map",
      name : "seeAll",
      component: () =>
          import(/*webpackChunkName: "settings"*/ "./pages/All.vue"),
    },

    {
      path : "/",
      name : "seeAllStories",
      component: () =>
          import(/*webpackChunkName: "settings"*/ "./pages/AllMaglie.vue"),
    },

    {
      path : "/favourites",
      name : "favourites",
      component: () =>
            import(/*webpackChunkName: "favourites"*/ "./pages/Favourites.vue"),
    },
    {
      path: "/notfound",
      alias: "*",
      name: "notfound",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // This is a dynamic loading.
      // Instead of loading everything in the "bundle" (app.js)
      // Magic comments are for params (eg webpackChunkName)
      component: () =>
        import(/* webpackChunkName: "notfound" */ "./pages/NotFound.vue"),
    },  


    
    {
      path: "/qr/:id",
      name: "qrcode",
      // component: () => 
      //       import(/*webpackChunkName: "favourites"*/ "path to the hypothetical component"),
      beforeEnter: (to, from, next) => {
        // Tri-LOG
        Common.addLog(new Vue(),to.path, res => {});

        QRUtils.mapQRCodeToElem(to.params.id).then(res => {
          if(res) {        
            next({ path : 'app/maglie-pivot/' + res.toString(), replace: true });
          }
          else 
            next({ name: 'notfound' });          
        })
      }
    },
    



  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});