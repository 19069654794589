<template>
  <div class="card">
    <article>
      <header>
        <div role="tab" id="headingOne" class="card-header">
            <n-button 
              type="primary" 
              block 
              :style="'margin:0; padding-right:0px; padding-left:0px; background-color:'+this.$props.color" 
              data-toggle="collapse"
              data-parent="#accordion"
              :href="`#${itemId}`"
              @click.prevent="activate"
              :aria-expanded="active"
              :aria-controls="`content-${itemId}`"
              class="normalLink">
                <b-row style="margin-left:0px; margin-right:0px;">
                  <b-col cols="10" md="11" style="text-align: left" align-self="center">
                    <slot name="title">
                      <span v-html="processedTitle"></span>
                    </slot>
                    &nbsp;<span @click="this.actionIcon" v-if="this.$props.titleIcon && $store.getters.getVideoCluster"><a href="#">{{$t("parVideo")}}</a></span>
                    &nbsp;<span @click="this.actionIcon2" v-if="this.$props.titleIcon2 && $store.getters.getClusterDescription['dcterms:description']"><a href="#">({{$t("descrizione")}})</a></span>
                  </b-col>
                  <b-col cols="2" md="1" align-self="center">
                    <slot name="icon">
                      <i v-if="!noIcon" class="now-ui-icons arrows-1_minimal-up arrowRightIcon" style="font-size:1.5em"></i>
                    </slot>
                  </b-col>
                </b-row>
            </n-button>
        </div>
      </header>
      <collapse-transition :duration="animationDuration">
        <div
          v-show="active"
          :id="`content-${itemId}`"
          role="tabpanel"
          :aria-labelledby="title"
          class="collapsed"
        >
          <div class="card-body">
            <slot></slot>
          </div>
        </div>
      </collapse-transition>
    </article>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import {Button} from '@/components'
const Common = require('@/Common.vue').default


export default {
  name: 'collapse-item',
  components: {
    CollapseTransition,
    [Button.name]: Button
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    id: String,
    noIcon: Boolean,
    initiallyActive: {
      type: Boolean,
      default: false
    },
    titleIcon: {
      type: Function,
      required: false
    },
    titleIcon2: {
      type: Function,
      required: false
    },
    color: {
      type: String,
      default: '#FFAB4C'
    },
    handler: {
      type: Function,
      required: false
    },
    el: {
      type: Object,
      required: false
    }
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: () => {}
    },
    removeItem: {
      default: () => {}
    },
    deactivateAll: {
      default: () => {}
    }
  },
  computed: {
    itemId() {
      return this.id || this.title;
    },
    processedTitle(){
      return Common.processMarkdown(this.title)
    }
  },
  data() {
    return {
      active: this.$props.initiallyActive,
      prevent: false
    };
  },
  methods: {
    actionIcon(){
      this.$props.titleIcon()
      this.prevent = true
    },
    actionIcon2(){
      this.$props.titleIcon2()
      this.prevent = true
    },
    activate() {
      if(this.prevent)
        this.prevent = false
      else {
        this.active = !this.active
        if(this.$props.handler)
          this.$props.handler(this.$props.el)
        Common.addUserStudyLog(this,'collapse', {
          'title': this.$props.title,
          'active': this.active
        })
      }
    },
  },
  mounted() {
    this.addItem(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  }
};
</script>
<style>
.dropdown-toggle:after, [data-toggle="collapse"][data-parent="#accordion"] i{
    transform: rotate(90deg);
}
</style>
