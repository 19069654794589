const lazyLoader = el => {
  function loadImage() {
    if (el && el.dataset.url !== null && el.dataset.url !== undefined && el.dataset.url !== '') {
      el.addEventListener("load", () => {
        setTimeout(() => el.classList.add("loaded"), 100);
      });
      //el.addEventListener("error", () => console.error("Error while loading image"));
      el.src = el.dataset.url;
    }
    else{
      //console.warn("L'immagine non è definita")
    }
  }

  function handleIntersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        loadImage();
        observer.unobserve(el);
      }
    });
  }

  function createObserver() {
    const options = {
      root: null,
      threshold: "0"
    };
    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(el);
  }
  if (window["IntersectionObserver"]) {
    createObserver();
  } else { //Fallback
    loadImage();
  }
}

export default {
    update: lazyLoader,
    inserted: lazyLoader
  };