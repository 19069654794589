import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const Common = require('@/Common.vue').default
// Fabio Language : language_sys in excluded
const excluded = ['acceptCookie','favourites','admin','lastCleared', 'language_sys', 'uuid', 'phase', 'systems', 'stories', 'forms']
function initializeUrlCache() {
    // Cancella ogni giorno la cache, oppure rinfresca sempre se siamo in modalità editor
    const expiration = 8.64e+7 // 1 giorno (da esprimere in secondi)
    if(localStorage.getItem("admin") === "Tri2022" || ((new Date()).getTime() - new Date(localStorage.lastCleared).getTime()) > expiration){
        clearStorage()
        return {}
    }
    try{
        var values = {},
            keys = Object.keys(localStorage),
            i = keys.length;
        while ( i-- ) {
            if(!excluded.includes(keys[i]))
                if(localStorage.getItem(keys[i]).includes('{'))
                    values[keys[i]] = JSON.parse(localStorage.getItem(keys[i]))
                else
                    values[keys[i]] = localStorage.getItem(keys[i])
        }
        return values;
    }
    catch(e){
        clearStorage()
        return {}
    }
}

function clearStorage() {
    const saved = {};
    excluded.forEach(el => {
        saved[el] = localStorage.getItem(el)
    })
    localStorage.clear()
    for (let key in saved) {
        if(saved[key] !== null)
            localStorage.setItem(key,saved[key])
    }
    localStorage.lastCleared = new Date()
}

export default new Vuex.Store({
    state: {
        pivotSelezionato : null, // L'oggetto corrispondente al pivot selezionato
        pivotImages: null, //Lista di media associati al pivot selezionato
        magliaSelezionata: null, // L'oggetto corrispondente alla maglia selezionata
        favourites: localStorage.getItem("favourites") === null ? [] : JSON.parse(localStorage.getItem("favourites")),
        urlCache: initializeUrlCache(), // Url => Obj
        radius_val: localStorage.getItem("radius_val") === null ? 1 : parseFloat(localStorage.getItem("radius_val")),
        language: localStorage.getItem("language") === null ? "en" : localStorage.getItem("language"),
        // Fabio Language
        language_sys: localStorage.getItem("language_sys") === null ? "en" : localStorage.getItem("language_sys"),
        magliaMedia: [],
        maglieCorrelate: [],
        maglieCorrelatePivot: [],
        videoCluster: null,
        clusterDescription: null,
        adminPw: "Tri2022",
        logPw: "Trilog2022",
        maglieHistory: sessionStorage.getItem("maglieHistory") === null ? {} : JSON.parse(sessionStorage.getItem("maglieHistory")),
        lat: 0,
        lng: 0,
        llm_messages : sessionStorage.getItem("llm_messages") == null ? [{
            "role": "system",
            "content": "Hi, I am your tour guide for the city of Turin. How may I help you?",
            "uri": ""
        }] : JSON.parse(sessionStorage.getItem("llm_messages")),
        current_question_generator: sessionStorage.getItem("current_question_generator") === null ? "" : sessionStorage.getItem("current_question_generator"),
        num_queries: sessionStorage.getItem("num_queries") ? JSON.parse(sessionStorage.getItem("num_queries")) : 0
    },
    mutations: {
        clearStorage: (state) => clearStorage(),
        /* PIVOT */
        setPivotSelezionato : (state,pivot) => state.pivotSelezionato = pivot,
        setPivotImages : (state,mediaList) => {state.pivotImages = mediaList},
        
        /* MAGLIA */
        setMagliaSelezionata: (state,maglia) => state.magliaSelezionata = maglia,
        setMagliaMedia: (state,mediaList) => state.magliaMedia = mediaList,
        addMagliaCorrelata : (state,maglia) => state.maglieCorrelate.push(maglia),
        addMagliaCorrelataPivot : (state,maglia) => state.maglieCorrelatePivot.push(maglia),
        setMaglieCorrelate: (state,value) => {state.maglieCorrelate = []; state.maglieCorrelatePivot = [];},
        setVideoCluster: (state,value) => state.videoCluster = value,
        setClusterDescription: (state,value) => state.clusterDescription = value,

        /* PREFERITI */
        addFavourite: (state,payload) => {
            var stop = false;
            for(var i = 0; i < state.favourites.length; i++){
                if(state.favourites[i].link === payload.link){
                    stop = true;
                    return;
                }
            }
            if(!stop){
                state.favourites.push({'link':payload.link, 'title':payload.title, 'img':payload.img})
                localStorage.setItem("favourites",JSON.stringify(state.favourites))
            }
        },
        importFavourite: (state,favourite) =>{
            state.favourites.push(favourite);
        },
        finishImport: (state) => {
            localStorage.setItem("favourites",JSON.stringify(state.favourites))
        },
        removeFavourite: (state,link) => {
            for(var i = 0; i < state.favourites.length; i++){
                if(state.favourites[i].link === link){
                    state.favourites.splice(i,1)
                    localStorage.setItem("favourites",JSON.stringify(state.favourites))
                    return;
                }
            }
        },
        removeFavouriteByIndex: (state,favIndex) => {
            state.favourites.splice(favIndex,1)
            localStorage.setItem("favourites",JSON.stringify(state.favourites))
        },
        clearFavourites: (state) => {
            state.favourites = [],
            localStorage.removeItem("favourites")
        },

        /* CACHE URL */
        addCachedUrl: (state, [url,payload]) => {
            try{
                localStorage.setItem(url,JSON.stringify(payload))
            }
            catch(e){
                // No space left. Clear all cache in localStorage.
                clearStorage()
            }
            state.urlCache[url] = payload
        },

        /* SETTINGS */
        updateRadius: (state, value) => {
            state.radius_val = value
            localStorage.setItem("radius_val",value)
        },

        // ANGELO Language
        updateLanguage: (state, value) => {
            state.language = value
            localStorage.setItem("language",value)
        },

        // Fabio Language
        updateLanguageSys: (state, value) => {
            state.language_sys = value
            localStorage.setItem("language_sys",value)
        },

        maglieHistory: (state,value) => {
            state.maglieHistory = value
            sessionStorage.setItem("maglieHistory",JSON.stringify(value))
        },

        lat: (state,value) => {
            state.lat = value
        },
        lng: (state,value) => {
            state.lng = value
        },

        addLlmMessageQA: (state, [q, a, ephemeral])=> {
            let already_done = state.llm_messages.findIndex(item => item.content === q)
            if(already_done > -1){
                // Remove all items in the array state.llm_messages which have the uri which is the same as the current window.location.pathname
                state.llm_messages = state.llm_messages.filter(item => item.uri !== window.location.pathname);
            }
            state.llm_messages.push({
                "role": "user",
                "content": q,
                "uri": window.location.pathname,
                "ephemeral": ephemeral
            })
            state.llm_messages.push({
                "role": "assistant",
                "content": a,
                "uri": window.location.pathname,
                "ephemeral": ephemeral
            })
            sessionStorage.setItem("llm_messages", JSON.stringify(state.llm_messages))
            state.num_queries++
            sessionStorage.setItem("num_queries", state.num_queries)
        },

        pruneLlmMessages: (state, uri) => {
            state.llm_messages = state.llm_messages.filter(item => item.uri !== uri || !item.ephemeral);
        },

        setQuestionGenerator: (state, value) => {
            state.current_question_generator = value
            sessionStorage.setItem("current_question_generator",value)
        },

    },
    actions: {
        initMagliaSelezionata: (context,[ref,url]) => {
            if(context.state.magliaSelezionata === null || context.state.magliaSelezionata === undefined || (context.state.magliaSelezionata['@id'] !== url)){
                context.commit('setMaglieCorrelate',[])
                Common.getElemByUrl(ref,url, function(r){
                    // Controllo autenticazione
                    if(Common.safeString(r.body,['dcterms:accessRights',0,'@value']) === "NO" && localStorage.getItem("admin") !== context.state.adminPw){
                        window.location.href = '/404'
                        return
                    }

                    context.commit('setMagliaSelezionata',r.body)
                    Common.getElementMedia(ref,r.body,function(m){context.commit('setMagliaMedia',m)})
                    var isPartOf = Common.safeString(r.body,["dcterms:isPartOf",0,"value_resource_id"])
                    if(isPartOf){
                        Common.getElemByUrl(ref,r.body["dcterms:isPartOf"][0]["@id"], function(risultato){
                            Common.getElementMedia(ref,risultato.body, function(risultato2){
                                context.commit('setVideoCluster',Common.getMainVideo(risultato2))
                            })
                            context.commit('setClusterDescription',risultato.body)
                        })
                        Common.getElemByPropertyId(ref,33,isPartOf, function(res){
                            for(var i = 0; i < res.body.length; i++){
                                if(res.body[i]["o:id"] !== r.body["o:id"] && (Common.safeString(res.body[i],['dcterms:accessRights',0,'@value']) === "YES" || localStorage.getItem("admin") === context.state.adminPw)){
                                    context.dispatch('addMagliaCorrelata',[ref,res.body[i]])
                                }
                            }
                        })
                    }
                    if(location.pathname.includes("maglia")){
                        var newC = context.state.maglieHistory
                        newC[r.body["tri:Titolo_" + context.state.language][0]["@value"]] = [location.pathname,r.body["tri:Titolo_" + context.state.language][0]["@value"]]
                        context.commit('maglieHistory',newC)
                    }
                    else{
                        Common.getElemById(ref,location.pathname.split('/').pop(),(resTitle) => {
                            var newC = context.state.maglieHistory
                            newC[r.body["tri:Titolo_" + context.state.language][0]["@value"]] = [location.pathname,r.body["tri:Titolo_" + context.state.language][0]["@value"] + " - " +resTitle.body["tri:Titolo_" + context.state.language][0]["@value"]]
                            context.commit('maglieHistory',newC)
                        })
                    }
                })
            }
            else{
                if(location.pathname.includes("maglia")){
                    var newC = context.state.maglieHistory
                    newC[context.state.magliaSelezionata["tri:Titolo_" + context.state.language][0]["@value"]] = [location.pathname,context.state.magliaSelezionata["tri:Titolo_" + context.state.language][0]["@value"]]
                    context.commit('maglieHistory',newC)
                }
                else{
                    Common.getElemById(ref,location.pathname.split('/').pop(),(resTitle) => {
                        var newC = context.state.maglieHistory
                        newC[context.state.magliaSelezionata["tri:Titolo_" + context.state.language][0]["@value"]] = [location.pathname,context.state.magliaSelezionata["tri:Titolo_" + context.state.language][0]["@value"] + " - " +resTitle.body["tri:Titolo_" + context.state.language][0]["@value"]]
                        context.commit('maglieHistory',newC)
                    })
                }
            }
        },
        addMagliaCorrelata: (context, [ref,el]) => {
            Common.getElementMedia(ref,el, function(mediaList){
                var obj = el
                obj["mediaList"] = mediaList
                context.commit('addMagliaCorrelata',obj)
            })
        },
        setPivotSelezionato: (context, [ref,el]) => {
            context.commit('setPivotSelezionato',el)
            Common.getElemByPropertyId(ref,259,el['o:id'], (res) => {
                res.body.forEach(elem => {
                    Common.getElemByUrl(ref,elem["tri:appartiene_a_maglia"][0]["@id"], (r) => {
                        context.dispatch('addMagliaCorrelataPivot',[ref,r.body])
                    })
                })
            })
        },
        addMagliaCorrelataPivot: (context, [ref,el]) => {
            Common.getElementMedia(ref,el, function(mediaList){
                var obj = el
                obj["mediaList"] = mediaList
                context.commit('addMagliaCorrelataPivot',obj)
            })
        },
    },
    modules: {},
    getters: {
        getPivotSelezionato : state => state.pivotSelezionato,
        getPivotImages: state => {return state.pivotImages},
        
        getMagliaSelezionata: state => state.magliaSelezionata,
        getMagliaMedia: state => state.magliaMedia,
        getMaglieCorrelate: state => state.maglieCorrelate,
        getMaglieCorrelatePivot: state => state.maglieCorrelatePivot,
        getVideoCluster: state => state.videoCluster,
        getClusterDescription: state => state.clusterDescription,

        getFavourites: state => state.favourites,
        getIndexedFavourites: state => {
            var indexed_favourites = {};
            state.favourites.forEach(elem => {
                indexed_favourites[elem.link] = elem;
            })
            return indexed_favourites;
        },

        getCached: state => state.urlCache,

        getRadiusVal: state => state.radius_val,
        getLanguage: state => state.language,
        // Fabio Language
        getLanguageSys: state => state.language_sys,
        getPw: state => state.adminPw,
        getPwLog: state => state.logPw,
        getmaglieHistory: state => state.maglieHistory,
        getLat: state  => state.lat,
        getLng: state => state.lng,
        getLlmMessages: state => state.llm_messages.map(({uri, ... rest}) => rest),
        getQuestionGenerator: state => state.current_question_generator,
        getNumQueries: state => state.num_queries
    }
});