var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropDown),expression:"closeDropDown"}],tag:"component",staticClass:"dropdown",class:[
    { show: _vm.isOpen },
    { dropdown: _vm.direction === 'down' },
    { dropup: _vm.direction === 'up' }
  ],attrs:{"aria-haspopup":"true","aria-expanded":_vm.isOpen},on:{"click":_vm.toggleDropDown}},[_vm._t("title",function(){return [_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"dropdown"},on:{"click":function($event){$event.preventDefault();return _vm.check($event)}}},[_c('b-icon',{attrs:{"icon":_vm.icon}}),_c('p',{staticClass:"no-icon",staticStyle:{"font-size":"1.2em"}},[_vm._v(" "+_vm._s(_vm.title))])],1)]}),_c('ul',{staticClass:"dropdown-menu",class:[
      { 'dropdown-menu-right': _vm.position === 'right' },
      { show: _vm.isOpen }
    ]},[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }